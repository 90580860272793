// Hook odpowiedzialny za pobranie listy płatności na dashboardzie

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

export interface IDashboardSettlementsListItem {
  symbol: string;
  date: string;
  payment_date: string;
  currency: string;
  value: number;
  value_formatted: string;
  balance: number;
  balance_formatted: string;
  payment_diff_days: number;
  bank_account_information: {
    bank_name: string;
    payment_method_name: string;
    account_bank_number: string;
  };
}

// typ zwracanych danych
export interface IResponse {
  all: IDashboardSettlementsListItem[];
  expired: IDashboardSettlementsListItem[];
  upcoming: IDashboardSettlementsListItem[];
}

const getDashboardSettlements = (): Promise<IResponse> => axios.get('dashboards/settlements');

export const useGetDashboardSettlements = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['dashboard-settlements'], getDashboardSettlements, options);
